import { Routes, Route, useMatch } from "react-router-dom";
import { lazy } from "react";
import "./App.css";

///////////////////////////메인페이지부분///////////////////////////////
import HomePage from "./home";
import FindIdPwComponent from "./auth/find";
import FindId from "./auth/findId";
import Findpw from "./auth/findpw";
import styled from "@emotion/styled";

// 컨텐츠 제작 부분 (Create)
const CreateCourseComponent = lazy(() => import("./create/course/index"));
const CreateCourseViewComponent = lazy(() => import("./create/course/view/index"));
const CreateWordChallengeComponent = lazy(() => import("./create/course/challenge/word/index"));
const CreateSentenceChallengeComponent = lazy(
  () => import("./create/course/challenge/sentence/index")
);
const CreateDubbingComponent = lazy(() => import("./create/course/dubbing/index"));
const CreateLandComponent = lazy(() => import("./create/course/mid/index"));
const CreatePrologueComponent = lazy(() => import("./create/course/prologue/index"));
const CreateDialogueComponent = lazy(() => import("./create/course/dialogue/index"));

// 선생님 페이지 (Admin)
const TeacherLogin = lazy(() => import("./teacher/login"));
const TeacherPrivateRoutes = lazy(() => import("./teacher/utils/PrivateRoutes"));
const TeacherDashboardPage = lazy(() => import("./teacher"));
const TeacherRecordPage = lazy(() => import("./teacher/record"));
const TeacherStudentPage = lazy(() => import("./teacher/student"));
const TeacherPaymentPage = lazy(() => import("./teacher/bill"));
const TeacherGroupPage = lazy(() => import("./teacher/group"));
const TeacherMypage = lazy(() => import("./teacher/mypage"));
const TeacherLevelTestList = lazy(() => import("./teacher/leveltest"));
const TeacherLevelTest = lazy(() => import("./teacher/leveltest/test"));
const TeacherResources = lazy(() => import("./teacher/resources"));
const PaymentComponent = lazy(() => import("./teacher/bill/pay"));
const PaymentHistoryComponent = lazy(() => import("./teacher/bill/History"));
const TeacherNotice = lazy(() => import("./teacher/notice"));
const TeacherSignup = lazy(() => import("./teacher/signup"));

// 기록 부분
const DubbingVideoComponent = lazy(() => import("./dubbing"));

const StudyRecord = lazy(() => import("./record/study"));
const TestResult = lazy(() => import("./record/test"));
const SnsSend = lazy(() => import("./record/sns"));

// 관리자 페이지 (Admin)
const AdminLogin = lazy(() => import("./admin/login"));
const AdminPrivateRoutes = lazy(() => import("./admin/utils/PrivateRoutes"));
const AdminDashboardPage = lazy(() => import("./admin/dashboard"));
const AdminStudentPage = lazy(() => import("./admin/student"));
const AdminPaymentPage = lazy(() => import("./admin/bill"));
const AdminAcademyPage = lazy(() => import("./admin/academy"));
const AdminAgentPage = lazy(() => import("./admin/agent"));
const AdminDownload = lazy(() => import("./admin/download"));
const AdminNotice = lazy(() => import("./admin/notices"));

// 단체수업
const StudyComponent = lazy(() => import("./study/home/index"));
const ContentMidComponent = lazy(() => import("./study/content/sid1ListComponent"));
const CourseComponent = lazy(() => import("./study/course/index"));

function App() {
  const pathCreate = useMatch("/create/*");
  const pathTeacher = useMatch("/teacher/*");
  const pathAdmin = useMatch("/admin/*");
  const pathDubbing = useMatch("/dubbing/*");
  const pathPayment = useMatch("/payment/*");
  const pathResult = useMatch("/record/*");
  const pathStudy = useMatch("/study/*");

  const renderTeacherRoutes = () => {
    return (
      <>
        <Routes>
          <Route path="/teacher" element={<TeacherPrivateRoutes />}>
            <Route path="/teacher/" index element={<TeacherDashboardPage />} />
            <Route path="/teacher/record" element={<TeacherRecordPage />} />
            <Route path="/teacher/student" element={<TeacherStudentPage />} />
            <Route path="/teacher/test/" element={<TeacherLevelTestList />} />
            <Route path="/teacher/test/new" element={<TeacherLevelTest />} />
            <Route path="/teacher/group" element={<TeacherGroupPage />} />
            <Route path="/teacher/bill" element={<TeacherPaymentPage />} />
            <Route path="/teacher/resources" element={<TeacherResources />} />
            <Route path="/teacher/mypage" element={<TeacherMypage />} />
            <Route path="/teacher/notice" element={<TeacherNotice />} />
          </Route>
          <Route path="/teacher/login" element={<TeacherLogin />} />
          <Route path="/teacher/signup" element={<TeacherSignup />} />
        </Routes>
      </>
    );
  };

  const renderStudyRoutes = () => {
    return (
      <>
        <Routes>
          {/* <Route path="/study/home" element={<StudyComponent />} /> */}
          <Route path="/study" element={<StudyComponent />} />
          <Route path="/study/content" element={<ContentMidComponent />} />
          <Route path="/study/course" element={<CourseComponent />} />
        </Routes>
      </>
    );
  };

  const renderAdminRoutes = () => {
    return (
      <>
        <Routes>
          <Route path="/admin" element={<AdminPrivateRoutes />}>
            <Route path="/admin/" index element={<AdminDashboardPage />} />
            <Route path="/admin/user" element={<AdminStudentPage />} />
            <Route path="/admin/academy" element={<AdminAcademyPage />} />
            <Route path="/admin/agent" element={<AdminAgentPage />} />
            <Route path="/admin/bill" element={<AdminPaymentPage />} />
            <Route path="/admin/download" element={<AdminDownload />} />
            <Route path="/admin/notice" element={<AdminNotice />} />
          </Route>
          <Route path="/admin/login" element={<AdminLogin />} />
        </Routes>
      </>
    );
  };

  const renderCreateRoutes = () => {
    return (
      <>
        <Routes>
          <Route path="/create/course" element={<CreateCourseComponent />} />
          <Route path="/create/course/view" element={<CreateCourseViewComponent />} />
          <Route path="/create/word/challenge" element={<CreateWordChallengeComponent />} />
          <Route path="/create/sentence/challenge" element={<CreateSentenceChallengeComponent />} />
          <Route path="/create/dubbing" element={<CreateDubbingComponent />} />
          <Route path="/create/course/prologue" element={<CreatePrologueComponent />} />
          <Route path="/create/course/dialogue" element={<CreateDialogueComponent />} />
          <Route path="/create/course/content" element={<CreateLandComponent />} />
        </Routes>
      </>
    );
  };

  const renderDubbingRoutes = () => {
    return (
      <MainContainer>
        <Routes>
          <Route path="/dubbing/:video" element={<DubbingVideoComponent />} />
        </Routes>
      </MainContainer>
    );
  };

  const renderPaymentRoutes = () => {
    return (
      <>
        <Routes>
          <Route path="/payment" element={<PaymentComponent />} />
          <Route path="/payment/history" element={<PaymentHistoryComponent />} />
        </Routes>
      </>
    );
  };

  const renderResultRoutes = () => {
    return (
      <>
        <Routes>
          <Route path="/record/study" element={<StudyRecord />} />
          <Route path="/record/test" element={<TestResult />} />
          <Route path="/record/sns" element={<SnsSend />} />
        </Routes>
      </>
    );
  };

  const renderDefaultRoutes = () => {
    return (
      <>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/find" element={<FindIdPwComponent />} />
          <Route path="/findid" element={<FindId />} />
          <Route path="/findpassword" element={<Findpw />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
      </>
    );
  };

  let component: any;

  if (pathCreate) {
    component = renderCreateRoutes();
  } else if (pathTeacher) {
    component = renderTeacherRoutes();
  } else if (pathAdmin) {
    component = renderAdminRoutes();
  } else if (pathDubbing) {
    component = renderDubbingRoutes();
  } else if (pathPayment) {
    component = renderPaymentRoutes();
  } else if (pathResult) {
    component = renderResultRoutes();
  } else if (pathStudy) {
    component = renderStudyRoutes();
  } else {
    component = renderDefaultRoutes();
  }

  return component;
}

export default App;

const MainContainer = styled.div`
  background-image: url(https://cnlrt5ox916.edge.naverncp.com/images/background.jpg);
`;
